<div class="content-section">
  <div class="forgot-password-body">


    <div class="card forgot-password-card">
      <div class="forgot-password-card-content">
        <div class="forgot-password-card-content-logo">
           <img src="assets/layout/images/logo.png"/>
        </div>
        <div class="forgot-password-card-content-form">
          <!-- <h2>{{ 'recoverPassword.Headline' | translate }}</h2>
          <p>{{ 'forgotPassword.message' | translate }}</p>
          -->
           <h2>Recover Password</h2>
          <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
          <div class="p-field">
            <input id="email" placeholder="E-mail" [(ngModel)]="email" type="email" pInputText>
          </div>

          <div class="p-field forgot-password-card-content-buttons">
            <button pButton type="button" label="Back To Login" routerLink="/login"></button>

            <button pButton type="button" label="Recover" [disabled]="!email" (click)="send()"></button>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>
