<app-is-active-app-message [isActiveApp]="isVersionHistoryActive" [appName]="'Every Version History'"></app-is-active-app-message>

<p-table *ngIf="isVersionHistoryActive" [value]="versions" [responsive]="true">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>{{'version' | translate}}</th>
      <th>{{'title' | translate}}</th>
      <th>{{'created' | translate}}</th>
      <th>{{'createdby' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-i="rowIndex" let-version>
    <tr [ngStyle]="{'background': i === 0 ? '#0f97c7' : 'white', 'color': i === 0 ? 'white' : ''}">
      <td>
        <button *ngIf="i !== 0 " style="margin-right: 5px;" pButton type="button"
                (click)="showVersion(version.Slug, version.Version)" label="{{'show' | translate}}" class="p-button-secondary"></button>       
        <button (click)="rollback(version.Version)" *ngIf="i !== 0 && permissions.canRollback" label="{{'rollback' | translate}}" pButton
                style="background: orange; border-color: orange;margin-right: 5px;" type="button"
                class="p-button-"></button>
      </td>
      <td>{{version?.Version}}</td>
      <td>{{version?.Title}}</td>
      <td>{{version?.LastEdited}}</td>
      <td>{{version?.CreatedBy}}</td>
    </tr>
  </ng-template>
</p-table>
