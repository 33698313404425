import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Router, NavigationEnd } from '@angular/router';
import { MenuService } from 'src/app/shared/services/menu.service';
import { filter } from 'rxjs/operators';
import { AppMainComponent } from 'src/app/app-main.component';
import { Menu } from 'src/app/shared/models/entities/menu';
import { MenuItem } from 'src/app/shared/models/ressources/menu-item';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-menuitem]',
  /* tslint:enable:component-selector */
  template: `
    <ng-container>
      <div *ngIf="showBadge" class="badge">{{badge}}</div>
      <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items" (mouseenter)="onMouseEnter()"
         (keydown.enter)="itemClick($event)"
         [attr.target]="item.target" [attr.tabindex]="0">
        <i *ngIf="!item.avatar" [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <img class="layout-menuitem-icon layout-menuitem-avatar" *ngIf="item.avatar" [src]="item.avatar" alt="" />
        <span class="layout-menuitem-text">{{item.label}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
      </a>
      <a (click)="itemClick($event)" (mouseenter)="onMouseEnter()" *ngIf="item.routerLink && !item.items"
         [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
         [routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target" [attr.tabindex]="0">
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{item.label}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
      </a>
      <div class="layout-menu-tooltip" style="display: none;">
        <div class="layout-menu-tooltip-arrow"></div>
        <div class="layout-menu-tooltip-text">{{item.label}}</div>
      </div>


      <ul *ngIf="item.items && item.items !== undefined && active"
          [@children]="((app.isHorizontal() || app.isSlim()) && root) ? (active ? 'visible' : 'hidden') :
      (active ? 'visibleAnimated' : 'hiddenAnimated')">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
          <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
      </ul>
    </ng-container>
  `,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.active-menuitem]': 'active'
  },
  animations: [
    trigger('children', [
      state('void', style({
        height: '0px'
      })),
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visibleAnimated, visibleAnimated => void',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  styleUrls: ['./app-menu-item.component.scss']
})
export class AppMenuItemComponent implements OnInit, OnDestroy {

  @Input() item: MenuItem;

  @Input() index: number;

  @Input() root: boolean;

  @Input() parentKey: string;

  badge: string;
  showBadge = false;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string;

  langDir: string;

  constructor(public app: AppMainComponent,
    public router: Router,
    private cd: ChangeDetectorRef,
    private menuService: MenuService,
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private cookieService: CookieService) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
      // deactivate current active menu
      if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
        this.active = false;
      }
      const lsValue = localStorage.getItem('user');
      if (!lsValue) { return; }
      let storageData = JSON.parse(JSON.parse(lsValue))
      this.langDir = this.languageService.getLangDir(storageData.Settings.Locale);
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(params => {
        if (this.app.isHorizontal() || this.app.isSlim()) {
          this.active = false;
        } else {
          if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
          } else {
            this.active = false;
          }
        }
      });
  }

  ngOnInit() {
    if (!(this.app.isHorizontal() || this.app.isSlim()) && this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }

    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);

    // Badge
    if (this.item.badgeResult && (this.item.badgeResult !== '') && this.cookieService.get('token')) {
      this.showBadge = true;
      this.badge = this.item.badgeResult;
      if (this.item.badgeEndpoint !== '') {
        setInterval(async () => {
          this.badge = await this.httpClient.get<string>(this.item.badgeEndpoint).toPromise();
        }, this.settingsService.getMenuBadgeUpdateInterval());
      }
    }
  }

  updateActiveStateFromRoute() {
    this.active = this.router.isActive(this.item.routerLink[0], this.item.items ? false : true);
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return true;
    }

    // navigate with hover in horizontal mode
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;
      this.app.hideMobileMenu();

      // reset horizontal menu
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.menuService.reset();
        this.app.menuHoverActive = false;
      }
    }
  }

  onMouseEnter() {
    // activate item on hover
    if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim()) && this.app.isDesktop()) {
      this.menuService.onMenuStateChange(this.key);
      this.active = true;
    }
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
